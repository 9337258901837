<template>
  <div class="swiper-container mainSlide">
    <div class="swiper-wrapper">
      <!-- <div class="swiper-slide" v-for="item in banner" v-bind:key="item.bannerIdx">
        <a :href="item.domain">
          <img :src="item.bannerImg" alt="slide">
        </a>
      </div> -->
      <div class="swiper-slide">
        <a>
          <img src="../../assets/img/main/mban1.png">
        </a>
      </div>
      <div class="swiper-slide">
        <a>
          <img src="../../assets/img/main/mban2.png">
        </a>
      </div>
      <div class="swiper-slide">
        <a>
          <img src="../../assets/img/main/mban3.png">
        </a>
      </div>
    </div>
    <!-- <div class="swiper-button-prev" role="button"></div>
    <div class="swiper-button-next" role="button"></div> -->
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'UiSwiper',
  title: 'Navigation',
  data () {
    return {
      bannerList: [
      ]
    }
  },
  mounted () {
    this.loadSwiper()
  },
  watch: {
    banner () {
      if (this.banner) {
        this.loadSwiper()
      }
    }
  },
  computed: {
    ...mapState([
      'banner'
    ])
  },
  methods: {
    loadSwiper () {
      // const list = this.banner
      // eslint-disable-next-line no-undef,no-new
      new Swiper('.swiper-container', {
        spaceBetween: 30,
        autoplay: { // 자동 슬라이드 설정 , 비 활성화 시 false
          delay: 4000, // 시간 설정
          disableOnInteraction: false // false로 설정하면 스와이프 후 자동 재생이 비활성화 되지 않음
        },
        loop: true,
        observer: true,
        observeParents: true,
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
          // renderBullet: function (index, className) {
          //   const title = list[index].bannerTitle || ''
          //   return '<div class="' + className + '"><span>' + title + '</span></div>'
          // }
        }
      })

      if (this.banner) {
        setTimeout(function () {
          this.$emit('loaded', true)
        }.bind(this), 500)
      }
    }
  }
}
</script>

<style src="@/styles/swiper.min.css"></style>

<style>
.swiper-container {
  z-index: 20;
}
.swiper-slide{
  display: flex;
  justify-content: center;
}
.swiper-container-horizontal>.swiper-pagination-bullets {
  position: absolute;
  max-width: 1316px;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: flex-end;
}
.swiper-pagination-bullet {
  opacity: 1;
  background: #fff;
  width: 12px;
  height: 12px;
}
.swiper-pagination-bullet-active {background: #ff49cc;}
/* .swiper-pagination-bullet {display: none;} */
</style>
